<template>
    <ul class="mainmenu">
				<li>
					<div class="menu-item">
						<router-link to="/index">首页</router-link>
					</div>
				</li>
				<li class="has-droupdown has-menu-child-item">
					<div class="menu-item">
						<router-link to="/solution">
							产品解决方案
						</router-link>
						<div class="toggles">
							<Icon name="plus" class="collapse-icon plus"/>
							<Icon name="minus" class="collapse-icon minus"/>
						</div>
					</div>
					<ul class="submenu" style="width: 220px">
						<li><a href="/solution#cloud-safety">云安全解决方案</a></li>
						<li><a href="/solution#mail-safety">邮件安全解决方案</a></li>
						<li><a href="/solution#device-safety">终端安全解决方案</a></li>
						<li><a href="/solution#eset" style="padding: 8px 2px">ESET企业网络安全解决方案</a></li>
					</ul>
				</li>
        <li class="has-droupdown has-menu-child-item">
					<div class="menu-item">
						<router-link to="/level-protection">
							等级保护
						</router-link>
						<div class="toggles">
							<Icon name="plus" class="collapse-icon plus"/>
							<Icon name="minus" class="collapse-icon minus"/>
						</div>
					</div>
					<ul class="submenu">
						<li><a href="/level-protection#about">等保概念</a></li>
						<li><a href="/level-protection#level">定级</a></li>
						<li><a href="/level-protection#filing">备案</a></li>
						<li><a href="/level-protection#developing">建设整改</a></li>
						<li><a href="/level-protection#testing">等保测评</a></li>
						<li><a href="/level-protection#product">整改产品</a></li>
					</ul>
        </li>
        <li class="has-droupdown has-menu-child-item">
					<div class="menu-item">
						<router-link to="/service">
							安全咨询与服务
						</router-link>
						<div class="toggles">
							<Icon name="plus" class="collapse-icon plus"/>
							<Icon name="minus" class="collapse-icon minus"/>
						</div>
					</div>
					<ul class="submenu" style="width: 160px">
							<li><a href="/service#safety-construction-planning">安全建设规划</a></li>
							<li><a href="/service#level-protection">等级保护咨询</a></li>
							<li><a href="/service#security-risk-assessment">安全风险评估</a></li>
							<li><a href="/service#vulnerability-scanning">漏洞扫描服务</a></li>
							<li><a href="/service#penetration-testing">渗透测试服务</a></li>
							<li><a href="/service#security-hardening">安全加固服务</a></li>
							<li><a href="/service#incident-response">应急响应服务</a></li>
							<li><a href="/service#about">安全培训服务</a></li>
						</ul>
        </li>
        <li>
					<div class="menu-item">
						<router-link to="/about">关于我们</router-link>
					</div>
				</li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
